import React from 'react'
import { Dialog } from '@material-ui/core'
import VideoPlayer from '../index'
import './index.scss'

export default function ModalVideo(props) {
  const { isOpen, onClose = () => {} } = props

  return (
    <Dialog className="ModalVideo-container" open={isOpen} onClose={onClose}>
      <VideoPlayer {...props} />
    </Dialog>
  )
}
