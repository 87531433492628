import React, { useMemo } from 'react'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { isEmpty, values, reduce } from 'lodash'
import { Container, Grid } from '@material-ui/core'
import { useReference } from 'store/Reference'
import { getEleExhibitionId } from 'utils/common'
import { MAP_DB_LANGUAGES, LANG_EN } from 'config/constants/common'
import './index.scss'

const MAX_EXHIBITIONS = 50

export default function ExhibitionSection(props) {
  const { t, i18n } = useTranslation('home')
  const { goToEleExhibitionId } = useReference()
  const { eventExhibitions = [] } = props
  const { language: currentLang = LANG_EN } = i18n || {}

  // Group event tracks by topic name and track name
  const eventTracks = useMemo(() => {
    // Function sort by sequenceNo
    function fnSortBySequenceNo(a, b) {
      return (b.sequenceNo !== null) - (a.sequenceNo !== null) || a.sequenceNo - b.sequenceNo
    }

    const dbLang = MAP_DB_LANGUAGES[currentLang]
    let countExhibitions = 0

    const objListTracks = reduce(
      eventExhibitions,
      (result, exhibitionItem) => {
        const { panelName = '', languageCode: exhibitionLang, ecmsEventTopicByTopicId } =
          exhibitionItem || {}
        const {
          topicId,
          topicName = '',
          languageCode: topicLang,
          sequenceNo: topicSequenceNo,
          ecmsEventTrackByTrackId,
        } = ecmsEventTopicByTopicId || {}
        const { trackId, trackName = '', languageCode: trackLang, sequenceNo: trackSequenceNo } =
          ecmsEventTrackByTrackId || {}

        // if invalid current lang => do not process
        if (
          dbLang !== exhibitionLang ||
          !panelName ||
          dbLang !== topicLang ||
          !topicName ||
          dbLang !== trackLang ||
          !trackName ||
          countExhibitions >= MAX_EXHIBITIONS
        ) {
          return result
        }

        const url = getEleExhibitionId(trackId, topicId)
        // if has exists track name => process topic
        if (result[trackId]) {
          const { topics = {} } = result[trackId]
          // if has exists topic name => add to current list exhibitions
          if (topics[topicId]) {
            const { exhibitions = [] } = topics[topicId]
            exhibitions.push({ panelName })
          } else {
            // add new topic
            topics[topicId] = {
              topicId,
              topicName,
              url,
              sequenceNo: topicSequenceNo,
              exhibitions: [{ panelName }],
            }
          }
        } else {
          // add new track name
          result[trackId] = {
            trackId,
            trackName,
            url,
            sequenceNo: trackSequenceNo,
            topics: {
              [topicId]: {
                topicId,
                topicName,
                url,
                sequenceNo: topicSequenceNo,
                exhibitions: [{ panelName }],
              },
            },
          }
        }
        countExhibitions++

        return result
      },
      {}
    )

    // sort topics by sequenceNo ASC
    const tracks = [...values(objListTracks)].map((trackItem) => {
      const { url: trackItemUrl = '', topics = {} } = trackItem
      const arrTopics = values(topics)
      arrTopics.sort(fnSortBySequenceNo)

      // Update track item url by first topic
      const [firstTopic] = arrTopics
      const { url: firstTopicUrl = '' } = firstTopic || {}

      return {
        ...trackItem,
        url: firstTopicUrl || trackItemUrl,
        topics: arrTopics,
      }
    })
    // sort tracks by sequenceNo ASC
    tracks.sort(fnSortBySequenceNo)

    return tracks || []
  }, [eventExhibitions, currentLang])

  /**
   * Go to DOM Element exhibition id on list exhibitions
   *
   * @param {String} eleExhibitionId Element exhibition id
   */
  function goToDOMEleExhibition(eleExhibitionId) {
    navigate('/exhibitions')
    goToEleExhibitionId(eleExhibitionId)
  }

  return (
    <div className="HomeScreen-section HomeExhibitionSection-area">
      <Container className="HomeScreen-sectionContainer">
        <h4 className="HomeScreen-title">{t('lbl_section_of_exhibition_en')}</h4>
        <div className="ExhibitionSection-list">
          {!isEmpty(eventTracks) &&
            eventTracks.map((eventTrack, index) => (
              <Grid container key={index} className="ExhibitionSection-item">
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={2}
                  xl={2}
                  className="ExhibitionSectionItem-track BoxShadow-effect"
                  onClick={() => goToDOMEleExhibition(eventTrack.url)}
                >
                  <h5 className="ExhibitionSectionItem-track_title">{eventTrack.trackName}</h5>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={8}
                  lg={10}
                  xl={10}
                  className="ExhibitionSectionItem-content"
                >
                  {!isEmpty(eventTrack.topics) &&
                    eventTrack.topics.map((topic, topicIndex) => {
                      return (
                        <Grid
                          key={topicIndex}
                          container
                          className="ExhibitionSectionSubItem-container"
                        >
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={4}
                            xl={4}
                            className="ExhibitionSectionSubItem-topic BoxShadow-effect"
                            onClick={() => goToDOMEleExhibition(topic.url)}
                          >
                            <h5 className="ExhibitionSectionSubItem-topic_title">
                              {topic.topicName}
                            </h5>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={8}
                            xl={8}
                            className="ExhibitionSectionSubItem-listContent"
                          >
                            <ul className="ExhibitionSectionSubItem-listContent">
                              {!isEmpty(topic.exhibitions) &&
                                values(topic.exhibitions).map((panel, panelIndex) => {
                                  return (
                                    <li key={panelIndex}>
                                      <p className="ExhibitionSectionSubItem-content">
                                        {panel.panelName}
                                      </p>
                                    </li>
                                  )
                                })}
                            </ul>
                          </Grid>
                        </Grid>
                      )
                    })}
                </Grid>
              </Grid>
            ))}
        </div>
      </Container>
    </div>
  )
}
