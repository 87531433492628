import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import Loadable from '@loadable/component'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import './index.scss'
import SEO from 'components/Seo'
import Heading from './Heading'
import EventSpace from './EventSpace'
import Industrialization from './Industrialization'
import SocialValue from './SocialValue'
import Webinars from './Webinars'
import ExhibitionSection from './ExhibitionSection'
import { useAuth } from 'store/Auth'
import { useAnalysis } from 'store/Analysis'
import { pageView } from 'utils/tracker'
import { fetchApiAnalysis } from 'utils/apis'
import { TRACKING } from 'config/constants/common'
const HomeSchedule = Loadable(() => import('./HomeSchedule'))

let timeOutUpdateData = null
let timeIntervalUpdateData = null

export default function Home(props) {
  const { t } = useTranslation('home')
  const [isUpdateEvent, setUpdateEvent] = useState(true)
  const { updateClientTzData } = useAuth()
  const { getTotalAnalysis } = useAnalysis()
  const { data } = props
  const { postgres } = data || {}
  const { allEcmsEventWebinars, allEcmsEventPanels, allEcmsTagGroups } = postgres || {}
  const { nodes: webinars = [] } = allEcmsEventWebinars || {}
  const { nodes: eventExhibitions = [] } = allEcmsEventPanels || {}
  const { nodes: tagGroups = [] } = allEcmsTagGroups || {}
  const trackingEventSource = TRACKING.SOURCE.HOME

  useEffect(() => {
    // Fetch analysis apis
    async function fetchApis() {
      await fetchApiAnalysis(getTotalAnalysis)
    }

    fetchApis()
    pageView(trackingEventSource)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Run every minute to update event's status
  useEffect(() => {
    const everySeconds = 60

    let currentSeconds = moment().format('s')
    currentSeconds = currentSeconds >= everySeconds ? 0 : currentSeconds
    const restSeconds = everySeconds - currentSeconds

    // Update data by every minute
    function updateDataByEveryMinute() {
      setUpdateEvent((isUpdateEvent) => !isUpdateEvent)
      updateClientTzData()
    }

    // set timeout call request api
    timeOutUpdateData = setTimeout(() => {
      if (restSeconds !== 0) {
        updateDataByEveryMinute()
      }

      timeIntervalUpdateData = setInterval(() => {
        updateDataByEveryMinute()
      }, everySeconds * 1000)
    }, restSeconds * 1000)

    return () => {
      clearInterval(timeIntervalUpdateData)
      clearTimeout(timeOutUpdateData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={'HomeScreen-container Layout-children'}>
      <SEO title={t('lbl_welcome_en')} />
      <Heading />
      <EventSpace />
      <Industrialization tagGroups={tagGroups} />
      <SocialValue tagGroups={tagGroups} />
      <Webinars webinars={webinars} isUpdateEvent={isUpdateEvent} />
      <ExhibitionSection eventExhibitions={eventExhibitions} />
      {typeof window !== 'undefined' && <HomeSchedule webinars={webinars} />}
    </div>
  )
}

export const query = graphql`
  query($eventId: Int!) {
    postgres {
      allEcmsEventWebinars(orderBy: START_DATE_ASC, condition: { eventId: $eventId }) {
        nodes {
          webinarType
          webinarName
          webinarId
          webinarDescription
          zoomLink
          meetingId
          meetingPwd
          languageCode
          startDate
          endDate
          ecmsUserByHostName {
            userName
            displayName
            position
            ecmsOrganisationByOrgId {
              orgName
            }
          }
          ecmsTimezoneByTimezoneId {
            timezoneCode
          }
          ecmsEventFilesByWebinarId {
            nodes {
              fileName
              keyCode
              url
            }
          }
        }
      }
      allEcmsEventPanels(orderBy: SEQUENCE_NO_ASC, condition: { eventId: $eventId }) {
        nodes {
          panelId
          panelName
          languageCode
          sequenceNo
          ecmsEventTopicByTopicId {
            topicId
            topicName
            languageCode
            sequenceNo
            ecmsEventTrackByTrackId {
              trackId
              trackName
              languageCode
              sequenceNo
            }
          }
        }
      }
      allEcmsTagGroups(orderBy: SEQUENCE_NO_ASC) {
        nodes {
          tagGroupName
          tagGroupId
          ecmsTagListsByTagGroupId(orderBy: HOME_DISPLAY_ORDER_ASC) {
            nodes {
              tagListName
              tagListId
            }
          }
        }
      }
    }
  }
`
