import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from '@material-ui/core'
import EventSpaceItem from './EventSpaceItem'
import BackgroundVideo from 'components/Video/BackgroundVideo'
import { HOME_VIDEO_INTRO_URL } from 'config/constants/common'
import defaultVideo from 'assets/videos/default.mp4'
import './index.scss'

const eventSpaces = [
  { title: 'lbl_webinar_en', icon: 'webinar', url: 'webinars' },
  { title: 'lbl_exhibition_en', icon: 'exhibition', url: 'exhibitions' },
]

export default function EventSpace() {
  const { t } = useTranslation('home')
  const [isOpenVideo, setOpenVideo] = useState(false)

  return (
    <div className="HomeScreen-section EventSpace-area">
      <Container className="HomeScreen-sectionContainer">
        <h4 className="HomeScreen-title">{t('lbl_event_space_en')}</h4>
        <div className="EventSpace-container">
          <div className="EventSpace-list">
            <div className="EventSpaceVideo-area">
              <div className="EventSpaceVideo-container">
                <BackgroundVideo
                  isOpen={isOpenVideo}
                  src={HOME_VIDEO_INTRO_URL || defaultVideo}
                  onClose={() => setOpenVideo(false)}
                />
                <span className="EventSpaceVideo-icon" onClick={() => setOpenVideo(true)} />
                <h4 className="EventSpaceVideo-title">{t('lbl_es_video_title_en')}</h4>
              </div>
            </div>
            {eventSpaces.map((eventSpaceItem, index) => (
              <EventSpaceItem key={index} data={eventSpaceItem} />
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}
