import React, { useMemo } from 'react'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { Container } from '@material-ui/core'
import Button from 'components/widgets/Button'
import { useAuth } from 'store/Auth'
import { useAnalysis } from 'store/Analysis'
import formatNumber from 'utils/common'
import { DEFAULT_TIMEZONE } from 'config/constants/timezone'
import { IS_HIDDEN_VIEWS } from 'config/constants/common'
import './index.scss'

const { timeCode: defaultTimeCode = '' } = DEFAULT_TIMEZONE

export default function Heading() {
  const { t } = useTranslation(['home', 'login'])
  const { clientTzData } = useAuth()
  const { totalAnalysis } = useAnalysis()
  const { views = 0 } = totalAnalysis

  // get client timezone
  const timezoneDesc = useMemo(() => {
    const { timeCode: clientTimeCode = '', utcOffsetStr: clientTzOffset } = clientTzData || {}

    return `(UTC${clientTzOffset}${defaultTimeCode !== clientTimeCode ? ` ${clientTimeCode}` : ''})`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientTzData])

  return (
    <div className="HomeScreen-section HomeHeading-area">
      <Container className="HomeScreen-sectionContainer">
        <div className="HomeHeading-mainContainer">
          <div className="HomeHeading-content">
            <div className="HomeHeading-title_container">
              <h1>{t('lbl_title')}</h1>
              <h3>
                {t('lbl_slogan_en')}
                <span className="HomeHeading-title_underline" />
              </h3>
              <p className="HomeHeading-title_desc">{t('lbl_ready_challenge_en')}</p>
              <p className="HomeHeading-title_time">
                Oct 7. Wed - 9. Fri&nbsp;
                <span>
                  Webinar 09:00 - 17:00 {defaultTimeCode} {timezoneDesc}
                </span>
              </p>
              <p className="HomeHeading-title_desc sp-view">{t('lbl_title_welcome_en')}</p>
            </div>
            <div className="HomeHeading-actions">
              <div className="HomeHeading-options">
                <Button
                  label={t('lnk_concept_en')}
                  hasBoxShadow
                  onClick={() => navigate('/concept')}
                />
              </div>
            </div>
          </div>
          {!IS_HIDDEN_VIEWS && (
            <div className="HomeHeading-other">
              <p className="HomeHeading-other_label">{t('lbl_join_with_us_en')}</p>
              <p className="HomeHeading-other_value">
                <span className="HomeHeading-views_value">{formatNumber(views)}</span>{' '}
                <span className="HomeHeading-views_label">{t('common:lbl_views_en')}</span>
              </p>
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}
