import React from 'react'
import ModalVideo from '../ModalVideo'
import defaultVideo from 'assets/videos/default.mp4'
import './index.scss'

export default function BackgroundVideo(props) {
  const { isOpen, onClose = () => {}, src = defaultVideo } = props

  return (
    <div className="BackgroundVideo-container">
      <div className="BackgroundVideo-content">
        <video
          controls={false}
          autoPlay
          muted
          loop
          id="BackgroundVideo-video"
          className="BackgroundVideo-video"
        >
          <source src={src} type="video/webm" />
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <ModalVideo
        isOpen={isOpen}
        video={{ url: src, fileCategory: 'mp4' }}
        hiddenPip
        onClose={onClose}
      />
    </div>
  )
}
