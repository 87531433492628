import React, { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { Container } from '@material-ui/core'
import AnimateLink from 'components/widgets/AnimateLink'
import WebinarItem from './WebinarItem'
import Button from 'components/widgets/Button'
import { LANG_EN, MAP_DB_LANGUAGES, WEBINAR_TYPES } from 'config/constants/common'
import { getFeaturedWebinars, getWebinarsByLang } from 'utils/common'
import './index.scss'

export default function Webinars(props) {
  const { t, i18n } = useTranslation('home')
  const [minHeight, setMinHeight] = useState(null)
  const { webinars = [], isUpdateEvent } = props
  const { language: currentLang = LANG_EN } = i18n || {}

  // get webinars by current languages
  const webinarsByLang = useMemo(() => {
    return getWebinarsByLang(webinars, MAP_DB_LANGUAGES[currentLang], {
      filterTypes: [WEBINAR_TYPES.NON_PANEL, WEBINAR_TYPES.PANEL],
    })
  }, [webinars, currentLang])

  // get 4 featured webinars
  const featuredWebinars = useMemo(() => {
    return getFeaturedWebinars(webinarsByLang, 4)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webinarsByLang, isUpdateEvent])

  useEffect(() => {
    const isBrowser = typeof window !== 'undefined'
    if (!isBrowser) return

    onResizeScreen()
    window.addEventListener('resize', onResizeScreen)

    return () => {
      window.removeEventListener('resize', onResizeScreen)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * on window resize
   */
  function onResizeScreen() {
    setMinHeight(0)
    const eleWebinarsTitle = document.getElementsByClassName('HomeWebinarItem-title')
    if (isEmpty(eleWebinarsTitle)) return

    // get max item's height
    const itemsHeight = [...eleWebinarsTitle].map((child) => child.getBoundingClientRect().height)
    const maxHeight = Math.max(...itemsHeight)
    setMinHeight(maxHeight)
  }

  return (
    <div id="HomeWebinars-area" className="HomeScreen-section HomeWebinars-area">
      <Container className="HomeScreen-sectionContainer">
        <h4 className="HomeScreen-title HomeWebinars-title">
          <span>{t('lbl_webinars_en')}</span>
          <AnimateLink to="/webinars">
            <Button label={t('btn_more_en')} className="HomeScreen-basicBtn" />
          </AnimateLink>
        </h4>
        <div className={`HomeWebinars-container ${featuredWebinars.length > 2 && 'between'}`}>
          {featuredWebinars.map((webinar, index) => (
            <WebinarItem
              key={index}
              webinar={webinar}
              index={index}
              isUpdateEvent={isUpdateEvent}
              minHeight={minHeight}
              setMinHeight={setMinHeight}
            />
          ))}
        </div>
      </Container>
    </div>
  )
}
