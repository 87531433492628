import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/widgets/Button'
import { useAuth } from 'store/Auth'
import { getFeaturedImg, getWebinarVideoURL } from 'utils/common'
import {
  isLiveEventStatus,
  formatPositionAndOrgName,
  getEventTimeDesc,
  getEventTime,
} from 'utils'
import { pageEvent } from 'utils/tracker'
import { TRACKING, LANG_JP, FILE_CODES } from 'config/constants/common'

export default function WebinarItem(props) {
  const { t, i18n } = useTranslation('home')
  const [isLiveEvent, setLiveEvent] = useState(false)
  const eleTitle = useRef(null)
  const { clientTzData } = useAuth()
  const { webinar, index, isUpdateEvent, minHeight } = props
  const {
    webinarId,
    webinarName = '',
    ecmsUserByHostName,
    ecmsEventFilesByWebinarId: filesAttach,
    startDateTimezone,
    endDateTimezone,
    zoomLink = '',
  } = webinar || {}
  const { displayName = '', position = '', ecmsOrganisationByOrgId } = ecmsUserByHostName || {}
  const { nodes: listFilesAttach = [] } = filesAttach || {}
  const { language: currentLang = LANG_JP } = i18n || {}
  const { orgName = '' } = ecmsOrganisationByOrgId || {}
  const { timeCode: clientTimeCode = '', utcOffsetStr: clientTzOffset = '' } = clientTzData || {}
  const trackingEventSource = `${TRACKING.SOURCE.HOME}_webinar${webinarId}`

  /**
   * Go to Webinar detail
   *
   * @param {Integer} webinarId Webinar id
   * @param {Integer} index Index of webinar in list
   */
  function goToWebinarDetail(index) {
    return
    // navigate(`/webinars/${webinarId}`, {
    //   state: { index: index + 1 || 0 },
    // })
  }

  // Get event time
  const { eventTime, eventTimeDesc } = useMemo(() => {
    return {
      eventTime: getEventTime(startDateTimezone, endDateTimezone),
      eventTimeDesc: getEventTimeDesc(clientTzOffset, clientTimeCode),
    }
  }, [startDateTimezone, endDateTimezone, clientTimeCode, clientTzOffset])

  // Get featured image
  const featuredImg = useMemo(() => {
    return getFeaturedImg(listFilesAttach, [FILE_CODES.FE3])
  }, [listFilesAttach])

  // get webinar video URL
  const webinarVideoURL = useMemo(() => {
    return getWebinarVideoURL(zoomLink)
  }, [zoomLink])

  /**
   * On click join zoom meeting
   */
  function joinZoom() {
    pageEvent(trackingEventSource, 'video')
  }

  // Run every minute to update state
  useEffect(() => {
    updateEventTimeLeft()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateEvent, startDateTimezone, endDateTimezone])

  /**
   * Update timeleft
   */
  function updateEventTimeLeft() {
    // get event status
    const isLive = isLiveEventStatus(startDateTimezone, endDateTimezone)
    setLiveEvent(isLive)
  }

  return (
    <div className="HomeWebinarItem-container">
      <div className="HomeWebinarItem-header">
        <div className="HomeWebinarItem-img_wrapper">
          <div
            className="HomeWebinarItem-img"
            style={{ backgroundImage: `url(${featuredImg})` }}
            onClick={() => goToWebinarDetail(index)}
          />
        </div>
        <h5
          className="HomeWebinarItem-title"
          ref={eleTitle}
          style={{ minHeight: minHeight ? `${minHeight}px` : 'auto' }}
          onClick={() => goToWebinarDetail(index)}
        >
          {webinarName}
        </h5>
        <div className="HomeWebinarItem-info">
          <p>{displayName}</p>
          <p className="HomeWebinarItem-desc">{formatPositionAndOrgName(position, orgName)}</p>
        </div>
      </div>
      <div className="HomeWebinarItem-footer">
        <div className="HomeWebinarItem-time">
          <span>{eventTime}</span>
          <span className="HomeWebinarItem-lang">{eventTimeDesc}</span>
        </div>
        <div className="HomeWebinarItem-actions">
          <Button
            label={t('webinars:btn_webinar_video_en')}
            className={`HomeScreen-basicBtn ${currentLang === LANG_JP && 'jp'}`}
            onClick={joinZoom}
            disabled={!webinarVideoURL}
            href={webinarVideoURL}
            target="_blank"
          />
          <div className={`HomeWebinarItem-status ${!isLiveEvent && 'interrupt'}`}>
            {isLiveEvent && (
              <>
                <span className={`HomeWebinarItem-status_icon ${isLiveEvent && 'live'}`} />
                {t('common:lbl_live_en')}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
