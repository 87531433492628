import React, { useMemo } from 'react'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { Container } from '@material-ui/core'
import { getTagItems } from 'utils/common'
import { TAG_GROUPS } from 'config/constants/common'
import './index.scss'

const labels = ['safety', 'security', 'fairness', 'efficiency']

export default function SocialValue(props) {
  const { t } = useTranslation('home')
  const { tagGroups = [] } = props
  const tagItems = getTagItems(tagGroups, TAG_GROUPS.TAG_SOCIAL_VALUE, { numOfItems: 4 })

  // Get list items social value tag
  const items = useMemo(() => {
    return labels.map((label, index) => {
      const { tagListId: tagItemId, tagListName: tagName = '' } = tagItems[index] || {}

      return {
        icon: label,
        label: t(tagName || `common:lbl_${label}_en`),
        tagItemId,
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagItems])

  /**
   * Go to page list exhibition with filter by tag item id
   *
   * @param {Integer} tagItemId Tag item id
   */
  function goToListExhibitions(tagItemId) {
    navigate('/exhibitions', {
      state: { filterTagGroup: { [TAG_GROUPS.TAG_SOCIAL_VALUE]: tagItemId } },
    })
  }

  return (
    <div className="HomeScreen-section SocialValue-area">
      <Container className="HomeScreen-sectionContainer">
        <h4 className="HomeScreen-title">{t('lbl_social_value_en')}</h4>
        <div className="SocialValue-container">
          <div className="SocialValue-items">
            {items.map((item, index) => (
              <div key={index} className="SocialValueItem-container">
                <div
                  className="SocialValueItem-intro BoxShadow-effect"
                  onClick={() => goToListExhibitions(item.tagItemId)}
                >
                  <span className={`Icon-ic Icon-${item.icon} SocialValueItem-icon`} />
                  <p className="SocialValueItem-label">{item.label}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}
