import React from 'react'
import { useTranslation } from 'react-i18next'
import AnimateLink from 'components/widgets/AnimateLink'

export default function EventSpaceItem(props) {
  const { t } = useTranslation('common')
  const { data } = props
  const { title = '', icon = '', url = '' } = data || {}

  return (
    <div className="EventSpaceItem-container">
      <AnimateLink to={`/${url}`}>
        <div className="EventSpaceItem-intro BoxShadow-effect">
          <span className={`Icon-ic Icon-${icon} EventSpaceItem-icon`} />
          <h4 className="EventSpaceItem-title">{t(`common:${title}`)}</h4>
        </div>
      </AnimateLink>
    </div>
  )
}
