import React, { useMemo } from 'react'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { Container } from '@material-ui/core'
import { TAG_GROUPS } from 'config/constants/common'
import { getTagItems } from 'utils/common'
import './index.scss'

const icons = ['mark', 'setting', 'light']
const labels = [
  'commercialization_technology_en',
  'competitive_technology_en',
  'cutting_edge_technology_en',
]

export default function Industrialization(props) {
  const { t } = useTranslation('home')
  const { tagGroups = [] } = props
  const tagItems = getTagItems(tagGroups, TAG_GROUPS.TAG_PROCESS, { numOfItems: 3 })

  // Get list items process tag
  const items = useMemo(() => {
    return icons.map((icon, index) => {
      const { tagListId: tagItemId } = tagItems[index] || {}

      return {
        icon,
        label: labels[index],
        tagItemId,
      }
    })
  }, [tagItems])

  /**
   * Go to page list exhibition with filter by tag item id
   *
   * @param {Integer} tagItemId Tag item id
   */
  function goToListExhibitions(tagItemId) {
    navigate('/exhibitions', {
      state: { filterTagGroup: { [TAG_GROUPS.TAG_PROCESS]: tagItemId } },
    })
  }

  return (
    <div className="HomeScreen-section Industrialization-area">
      <Container className="HomeScreen-sectionContainer">
        <h4 className="HomeScreen-title Industrialization-title">
          {t('lbl_toward_industrialization_en')}
        </h4>
        <div className="Industrialization-container">
          <div className="Industrialization-items">
            {items.map((item, index) => (
              <div
                key={index}
                className="IndustrializationItem-container BoxShadow-effect"
                onClick={() => goToListExhibitions(item.tagItemId)}
              >
                <span className={`Icon-ic Icon-group_${item.icon} IndustrializationItem-icon`} />
                <p
                  className="IndustrializationItem-desc"
                  dangerouslySetInnerHTML={{ __html: t(`lbl_${item.label}`) }}
                />
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  )
}
